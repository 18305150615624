console.log("Loaded");
// get donate buttons and add event listeners to show popup on click
let donatePopupElemId = "donate-popup";
let donateButtonElems = document.querySelectorAll(".donate-button");
const donationForm = document.forms["donation-form"];
const amountInputFieldElem = document.querySelector(
    "#donate-popup input#donation-amount"
);
const emailInputElem = document.querySelector(".email-input-section input");
const fixedAmountBoxInputElems = document.querySelectorAll(
    ".fixed-amount-boxes .fixed-amount-box input"
);
const finalNoticeAmountElem = document.querySelector(
    ".final-notice #payment-amount"
);
const makePaymentBtnElem = document.querySelector(
    ".popup-modal button.make-payment"
);
const closeModalButton = document.querySelector("#donate-modal-close");

closeModalButton.addEventListener("click", e => {
    document.getElementById(donatePopupElemId).classList.remove("active");
    resetDonationForm();
});

donateButtonElems.forEach(donateButton =>
    donateButton.addEventListener("click", e => {
        e.preventDefault();
        console.log("Added event listeners to donate buttons");
        showPopup(donatePopupElemId);
    })
);

emailInputElem.addEventListener("focus", e => {
    document
        .querySelector(".email-input-section .icon-box")
        .classList.add("active");
});

emailInputElem.addEventListener("blur", e => {
    document
        .querySelector(".email-input-section .icon-box")
        .classList.remove("active");
});

// Control for fixed amount input boxes
fixedAmountBoxInputElems.forEach(input => {
    input.addEventListener("change", e => {
        if (input.checked) {
            // Remove 'active' class from all other input's parent elements
            fixedAmountBoxInputElems.forEach(otherInput => {
                if (otherInput !== input) {
                    otherInput.parentElement.classList.remove("active");
                }
            });

            // clear amount input field
            amountInputFieldElem.value = null;

            input.parentElement.classList.add("active");

            updateDonationAmount(input.value);
        }
    });
});

// amount input field controls
amountInputFieldElem.addEventListener("keyup", e => {
    fixedAmountBoxInputElems.forEach(input => {
        // remove selection from fixed amount inputs when user types amount in input field
        input.parentElement.classList.remove("active");
    });
    updateDonationAmount(e.target.value);
});

amountInputFieldElem.addEventListener("invalid", function () {
    if (this.validity.valueMissing) {
        this.setCustomValidity(
            "Please fill out this field or select from the options on the left"
        );
    } else {
        this.setCustomValidity("");
    }
});

// Reset the validation message on input change
amountInputFieldElem.addEventListener("input", function () {
    this.setCustomValidity("");
});

const formAlertElem = document.querySelector("#form-alert");

// submit donation form
donationForm.addEventListener("submit", async e => {
    e.preventDefault();
    showDonateButtonLoader();

    fetch("https://pay.ktechhub.com/api/v1/clients/general/make-payment/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: emailInputElem.value.trim(),
            amount: parseInt(amountInputFieldElem.value),
            public_key: "74b4421f-dea0-4192-98c8-eafcd338a8e9",
        }),
    })
        .then(async response => {
            hideDonateButtonLoader();
            if (response.ok) {
                let data = await response.json();
                console.log(data);
                resetDonationForm();
                // Open a new tab and redirect the user to another page
                let paystackLinkElem = document.createElement("a");
                paystackLinkElem.href = data.data.authorization_url;
                paystackLinkElem.rel = "external";
                paystackLinkElem.click();
            } else {
                // If response is not OK, handle error
                const errorData = await response.json();
                let errorMessages = [];
                for (const key in errorData) {
                    if (errorData.hasOwnProperty(key)) {
                        errorMessages = errorMessages.concat(errorData[key]);
                    }
                }
                formAlertElem.textContent = errorMessages.join("\n");
                formAlertElem.style.opacity = "1";
                setTimeout(() => {
                    formAlertElem.style.opacity = "0";
                }, 3000);
            }
        })
        .catch(error => alert(error));
});

function showPopup(popupId) {
    let popupElem = document.getElementById(popupId);
    console.log(popupId);
    popupElem.classList.add("active");
}

function hidePopup(popupId) {
    const popupElem = document.getElementById(popupId);
    popupElem.classList.remove("active");
}

function updateDonationAmount(amount) {
    // update in form
    amountInputFieldElem.value = amount;

    // update in final notice paragraph
    finalNoticeAmountElem.innerHTML = `GH₵ ${amount}`;
}

function showDonateButtonLoader() {
    makePaymentBtnElem.classList.add("loading");
}

function hideDonateButtonLoader() {
    makePaymentBtnElem.classList.remove("loading");
}

function resetDonationForm() {
    fixedAmountBoxInputElems.forEach(input => {
        input.parentElement.classList.remove("active");
    });
    donationForm.reset();
}
